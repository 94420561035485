import React from 'react'
import Helmet from 'react-helmet'
import { Link,graphql } from 'gatsby'
import get from 'lodash/get'

import styled from 'styled-components'
import Layout from '../components/Layout'

class BlogPostTemplate extends React.Component {
  render() {
    const post = get(this, 'props.data.markdownRemark');
    const siteTitle = get(this.props, 'data.site.siteMetadata.title');
    const siteDescription = post.frontmatter.description ? post.frontmatter.description: post.excerpt
    const { previous, next } = this.props.pageContext
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    console.log(this.props.location.href)
    const siteUrl = get(
      this,
      'props.data.site.siteMetadata.siteUrl'
    )
    const heroImage = post.frontmatter.image ? require('../assets/img/headers/' + post.frontmatter.image) : require('../assets/img/headers/pans.png');
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            //html tags
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: post.frontmatter.keywords},
            { itemprop: 'name', content: post.frontmatter.title },
            { itemprop: 'description', content: siteDescription},
            { itemprop: 'image', content: siteUrl + heroImage},
            //facebook tags
            {property: 'og:title', content: post.frontmatter.title},
            {property: 'og:type', content: 'article'},
            {property: 'og:image', content: siteUrl + heroImage},
            {property: 'og:url', content: this.props.location.href},
            {property: 'og:description', content: siteDescription},
            {property: 'og:site_name', content: 'JenniferWadella.com'},

            //twitter tags
            {property: 'twitter:card', content: 'summary'},
            {property: 'twitter:title', content: post.frontmatter.title},
            {property: 'twitter:creator', content: '@likeOMGitsFEDAY'},
            {property: 'twitter:image', content: siteUrl + heroImage},
            {property: 'twitter:url', content: siteUrl},
            {property: 'twitter:description', content: siteDescription}
          ]}
          title={`${post.frontmatter.title} | ${siteTitle}`}
        />
        <Hero style={{ backgroundImage:"url("+heroImage+")", backgroundSize:"cover"}} className="intro">
          <div className="intro-body">
              <div className="container">
                  <div className="row justify-content-center">
                      <div className="col-md-12">
                          <h1 className="brand-heading">{post.frontmatter.title}</h1>
                          <p className="intro-text">{post.frontmatter.description}</p>
                      </div>
                  </div>
              </div>
          </div>
        </Hero>
        <BlogPost>
          <div className="container">
            <p className="date">{post.frontmatter.date}</p>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
                {/* <ul
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    listStyle: 'none',
                    padding: '5em 0 1em 0',
                  }}
                >
                  <li>
                    {
                      previous &&
                      <Link to={previous.fields.slug} rel="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    }
                  </li>
                  <li>
                    {
                      next &&
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title} →
                      </Link>
                    }
                  </li>
                </ul> */}
          </div>
        </BlogPost>
      </Layout>
    )
  }
}

const BlogPost = styled.div`
  background-color:#fff;
  color: #696969;
  box-sizing: border-box;
  overflow: hidden;

  .intro .intro-body {
    display: flex;
    align-content: center;
    max-height: 250px;
  }
  h1 {
      font-family: 'Open Sans Condensed', sans-serif;
      text-transform: capitalize;
      font-size: 52px;
      margin: 45px -100% 15px;
      padding: 10px 100%;
      background-color: aliceblue;
  }
  a {
      font-weight: 600;
  }
  h2 {
      font-family: 'Open Sans Condensed', sans-serif;
      text-transform: capitalize;
      margin: 30px -60px 15px;
      padding: 10px 60px !important;
      border-top: 5px solid aliceblue;
      border-bottom: 5px solid aliceblue;
  }
  h2 + blockquote {
      margin-top: 30px;
  }
  h3 {
      font-family: 'Open Sans Condensed', sans-serif;
      margin: 15px 0 0;
      padding: 10px 0 !important;
      border-bottom: 3px solid aliceblue;
  }
  strong {
    font-weight: 700;
    color: #666666;
  }
  code.language-text {
    background: rgba(0,0,0,.05);
    color: #000;
    padding: 3px 4px;
    text-shadow: none;
  }
  .gatsby-highlight {
    background: #F25D95;
    padding: 2.5em;
    margin: .5em 0em;
    pre[class*="language-"]  {
      margin: 0 !important;
      box-shadow: 0px 0px 10px #000;
    }
  }
  
  .imageCaption {
    text-align:center;
    font-family: medium-content-sans-serif-font,"Lucida Grande","Lucida Sans Unicode","Lucida Sans",Geneva,Arial,sans-serif;
    font-size: .8em;
    margin-top: -25px;
    margin-bottom: 3em;
  }

  .intro .intro-body .brand-heading {
      font-family: 'Open Sans Condensed', sans-serif;
      text-transform: capitalize;
      font-size: 120px;
      margin: 0;
      text-shadow: 0 1px 0 #ccc, 
                0 2px 0 #c9c9c9,
                0 3px 0 #bbb,
                0 4px 0 #b9b9b9,
                0 5px 0 #aaa,
                0 6px 1px rgba(0,0,0,.1),
                0 0 5px rgba(0,0,0,.1),
                0 1px 3px rgba(0,0,0,.3),
                0 3px 5px rgba(0,0,0,.2),
                0 5px 10px rgba(0,0,0,.25),
                0 10px 10px rgba(0,0,0,.2),
                0 20px 20px rgba(0,0,0,.5);
  }
  .date {
      padding-top: 20px;
  }
  .container p, .container ul {
      font-family: 'Open Sans', sans-serif;
      font-weight: 300;
      line-height: 2.5;
      font-size: 18px;
      color: #000;
  }
  .container ul {
      line-height: 1.5;
      margin-bottom: 60px;
      //margin-top: -30px;
  }
  a + p, img + p {
      margin-top: 30px;
  }
  p img{
    margin:0px auto; 
    display:block;
  }
  blockquote {
      box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
  }
  blockquote p {
      font-size: 21px !important;
      line-height: 1.75 !important;
      padding: 20px;
  }

  ol, ul {
    //font-size: 1.15em;
    margin-left: 1.5em;
    margin-top: 1em;
    font-family: 'Open Sans',sans-serif;
    color: #000;
    li {
      font-size: 18px;
      line-height: 1.5em;
      p {
        //font-size: 1em;
      }
    }
  }

  @media(min-width: 768px){
      .container {
        max-width: 740px;
      }
    }
`
export default BlogPostTemplate

const Hero = styled.div`
  background-repeat: no-repeat;
  background-position: center center;
  background-size:cover;
  padding: 5%;

  @media(min-width:767px) {
    .intro-body .brand-heading {
        font-size: 70px;
    }
  }

`

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
        siteKeywords
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
        image
        thumbnail {
          image:childImageSharp {
            sizes(maxWidth: 2000 ) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        keywords
      }
    }
  }
`
